import Lottie from "react-lottie";
import snowAnimation from "./assets/lottie/snow.json";
import "./styles/christmas-font.css";

interface LoaderProps {
  isExiting: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isExiting }) => {
  return (
    <div
      className={`relative bg-[#B00B09] w-svw h-svh flex justify-center items-center `}
    >
      <div className="absolute inset-0 z-1 pointer-events-none">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: snowAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={"100%"}
          width={"100%"}
          style={{ opacity: 1 }}
        />
      </div>
      <h1 className="text-[#F0B67F] text-center text-8xl z-10 christmas-font">
        🎄New Year is Loading...🎄
      </h1>
    </div>
  );
};

export default Loader;
