import React, { useState, useEffect } from "react";
import Loader from "./Loader";

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [MainContent, setMainContent] = useState<React.ComponentType | null>(
    null
  );

  useEffect(() => {
    import("./MainContent")
      .then((module) => {
        setMainContent(() => module.default); // Set the imported component
        setTimeout(() => setIsExiting(true), 500); // Match animation duration
        setTimeout(() => setIsLoaded(true), 1000); // Delay for animation completion
      })
      .catch((error) => {
        console.error("Failed to load the component:", error);
      });
  }, []);

  if (!isLoaded || !MainContent) {
    return <Loader isExiting={isExiting} />;
  }

  return <MainContent />;
};

export default App;
